// common
export const DAY_MINUTES = 24 * 60;
export const HOUR_MINUTES = 60;

export const PAGINATION_COUNT = Object.freeze({
  SMALL: 12,
  MIDDLE: 24,
  BIG: 36,
  HUGE: 48,
});

export const SORT_BY_TYPE = Object.freeze({
  ALPHABETICAL: 'Alphabetical',
  DATE_CREATED: 'Date created',
  LAST_OPENED: 'Last opened',
  LAST_MODIFIED: 'Last modified',
});

export const FORMAT_DAY = Object.freeze({
  Sun: 'Sunday',
  Mon: 'Monday',
  Tue: 'Tuesday',
  Wed: 'Wednesday',
  Thu: 'Thursday',
  Fri: 'Friday',
  Sat: 'Saturday',
});

export const DAY_KEYS = Object.freeze(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);

export const WEEKEND = Object.freeze([
  'Sat',
  'Sun',
]);


// appointment related
export const MAX_SUMM_LEN = 100;
export const MAX_LOC_LEN = 100;
export const MAX_DESC_LEN = 2000;
export const MIN_BOOKING_LIMIT = 0;
export const MAX_BOOKING_LIMIT_PER_DAY = 96;
export const MIN_CAPACITY = 2;
export const MAX_CAPACITY = 200;

export const DEFAULT_CCI_CAPACITY = 2;
export const TEAM_APPT_CAPACITY = 1;

// max booking time values in days
export const MAX_BOOKING_TIME = 1000;
export const MIN_BOOKING_TIME = 1;

// duration values in minutes
export const MIN_DURATION = 1;
export const MAX_DURATION = 720;
export const ONEOFF_MIN_DURATION = 5; // Different from MIN_DURATION by design
export const ONEOFF_MAX_DURATION = MAX_DURATION;
export const DEFAULT_DURATION = 30;
export const MIN_START_TIME_INCREMENT = 5; // Different from MIN_DURATION by design
export const MAX_START_TIME_INCREMENT = MAX_DURATION;
export const DEFAULT_START_TIME_INCREMENT = 30;
export const MIN_CUSHION = 0;
export const MAX_CUSHION = 14340;
export const MIN_BUFFER = 0;
export const MAX_BUFFER = 240;

export const MAX_LEN_ANSWER_CHOICE = 8192;
export const MAX_ANSWER_CHOICES = 50;
export const MIN_ANSWER_CHOICES = 1;
export const MAX_CUSTOM_FIELDS = 10;

export const MAX_ROUTING_QS = 20;

export const APPT_TYPE = Object.freeze({
  RECURRING: 'recurring',
  CUSTOM: 'custom',
  ROUND_ROBIN: 'roundRobin',
  COLLECTIVE: 'collective',
  ONE_OFF: 'instant',
  POLL: 'poll',
});

export const ADHOC_TYPE = Object.freeze({
  ONE_OFF: 'instant',
  POLL: 'poll',
});

export const APPT_FORM_MODE = Object.freeze({
  CREATE: 'create',
  EDIT: 'edit',
  // SUL = Single use link
  EDIT_SUL: 'edit SUL',
});

export const APPT_FORM_TYPE = Object.freeze({
  STANDARD: 'standard',
  ADHOC: 'adhoc',
  APPT_TYPE_FORM: 'apptTypeForm',
});

export const APPT_INTERVAL_TYPE = Object.freeze({
  UNLIMITED: 'unlimited',
  FIXED: 'fixed',
});

export const REDIRECT_TYPE = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const APPT_CREATOR_TYPE = Object.freeze({
  USER: 'user', // user create for self
  ORG: 'organizer', // admin user create for anyone else
});

export const CAPACITY_TYPE = Object.freeze({
  ONE: 'one',
  MULTIPLE: 'multiple',
});

export const ADD_ON_TYPE = Object.freeze({
  OFFLINE: 'offline',
  ZOOM_MEETING: 'zoomMeeting',
  ZOOM_PHONE: 'zoomPhone',
  OUT_BOUND_CALL: 'outboundCall', // host call booker
  IN_BOUND_CALL: 'inboundCall', // booker call host
  IN_PERSON: 'physical',
  ASK_INVITEE: 'askInvitee',
  CUSTOM: 'custom',
  GOOGLE_MEETING: 'googleMeeting',
  TEAMS_MEETING: 'teamsMeeting',
});

export const AVAIL_STATUS = Object.freeze({
  AVAILABLE: 'available',
  UNAVAILABLE: 'unavailable',
});

export const PERIOD_TYPE = Object.freeze({
  MOVING: 'moving', // calendar days
  AVAILABLE_MOVING: 'availableMoving', // business days
  UNLIMITED: 'unlimited',
  ROLLING_WINDOW: 'rollingWindow',
});

export const APPT_POOLING_TYPE = Object.freeze({
  COLLECTIVE: 'collective',
  ROUND_ROBIN: 'roundRobin',
});

export const APPT_STATUS = Object.freeze({
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
});

export const RESCHEDULING_TYPE = Object.freeze({
  ORIGINAL: 'original',
  ROUND_ROBIN: 'roundRobin',
});

export const DISTRIBUTION_TYPE = Object.freeze({
  EQUAL: 'equal',
  AVAILABILITY: 'availability',
});

export const MAX_USER_SEARCH_RESULTS = 5;
export const MAX_TEAM_HOSTS = 50;


// event related
export const EVENT_STATUS = Object.freeze({
  CONFIRMED: 'confirmed',
  CANCELLED: 'cancelled',
});

export const CONFLICT_CODE = Object.freeze({
  CALENDAR: 'calendar',
  DAY_MAX: 'dayMax',
  WEEK_MAX: 'weekMax',
  MONTH_MAX: 'monthMax',
  OVERLAP: 'overlap',
  PAST: 'past',
  TOO_SOON: 'tooSoon',
  DURATION: 'duration',
  EVENT: 'event',
  RANGE: 'range',
  BUFFER: 'buffer',
  ROUND_ROBIN: 'roundRobin',
  HOST: 'host',
  OVERBOOKED: 'overbooked', // UI only
  UNAVAILABLE: 'outsideAvailableHours', // UI only
});

export const CONFLICT_LABEL_MAP = Object.freeze({
  [CONFLICT_CODE.DAY_MAX]: 'troubleshoot.dayMaxHead',
  [CONFLICT_CODE.WEEK_MAX]: 'troubleshoot.weekMaxHead',
  [CONFLICT_CODE.MONTH_MAX]: 'troubleshoot.monthMaxHead',
  [CONFLICT_CODE.OVERLAP]: 'troubleshoot.overlapHead',
  [CONFLICT_CODE.PAST]: 'common.past',
  [CONFLICT_CODE.EVENT]: 'troubleshoot.eventHead',
  [CONFLICT_CODE.TOO_SOON]: 'troubleshoot.tooSoonHead',
  [CONFLICT_CODE.DURATION]: 'troubleshoot.durationHead',
  [CONFLICT_CODE.CALENDAR]: 'troubleshoot.calendarHead',
  [CONFLICT_CODE.RANGE]: 'troubleshoot.rangeHead',
  [CONFLICT_CODE.BUFFER]: 'troubleshoot.bufferHead',
  [CONFLICT_CODE.ROUND_ROBIN]: 'troubleshoot.roundRobinHead',
  [CONFLICT_CODE.OVERBOOKED]: 'troubleshoot.overbookedHead',
  [CONFLICT_CODE.UNAVAILABLE]: 'troubleshoot.outsideAvailableHoursHead',
  [CONFLICT_CODE.HOST]: 'troubleshoot.hostHead',
});

export const CONFLICT_DESC_MAP = Object.freeze({
  [CONFLICT_CODE.DAY_MAX]: 'troubleshoot.dayMaxSub',
  [CONFLICT_CODE.WEEK_MAX]: 'troubleshoot.weekMaxSub',
  [CONFLICT_CODE.MONTH_MAX]: 'troubleshoot.monthMaxSub',
  [CONFLICT_CODE.OVERLAP]: 'troubleshoot.overlapSub',
  [CONFLICT_CODE.PAST]: 'troubleshoot.pastSub',
  [CONFLICT_CODE.EVENT]: 'troubleshoot.eventSub',
  [CONFLICT_CODE.TOO_SOON]: 'troubleshoot.tooSoonSub',
  [CONFLICT_CODE.DURATION]: 'troubleshoot.durationSub',
  [CONFLICT_CODE.CALENDAR]: 'troubleshoot.calendarSub',
  [CONFLICT_CODE.RANGE]: 'troubleshoot.rangeSub',
  [CONFLICT_CODE.BUFFER]: 'troubleshoot.bufferSub',
  [CONFLICT_CODE.ROUND_ROBIN]: 'troubleshoot.roundRobinSub',
  [CONFLICT_CODE.OVERBOOKED]: '',
  [CONFLICT_CODE.UNAVAILABLE]: '',
  [CONFLICT_CODE.HOST]: 'troubleshoot.hostSub',
});

export const USER_RESPONSE_STATUS = Object.freeze({
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
});

export const APPT_NOTIF_TYPE = Object.freeze({
  CALENDAR: 'calendar',
  EMAIL: 'email',
});


// workflows related
export const WF_METHOD = Object.freeze({
  EMAIL: 'email',
  TEXT: 'text',
  CALENDAR: 'calendar invite', // only exists in UI
});

export const WF_ACTION_RECEIVER = Object.freeze({
  BOOKER: 'booker',
  HOST: 'host',
  BOTH: 'both', // only exists in UI
});

export const WF_TRIGGER_TYPE = Object.freeze({
  EVENT_BOOKED: 'book',
  EVENT_START: 'start',
  EVENT_BEFORE_START: 'before_start', // only exists in UI
  EVENT_END: 'end',
  EVENT_CANCEL: 'cancel',
});

export const WF_TYPE = Object.freeze({
  CCI: 'groupCCI',
  CALENDAR_INVITE: 'calendarInvitation', // only exists in UI
  CREATE_NEW: 'createNew', // only exists in UI
  APPT_EMAIL_REMINDER: 'attendeeEmailReminder', // only exists in UI
  APPT_SMS_REMINDER: 'attendeeSmsReminder', // only exists in UI
  APPT_EMAIL_FOLLOW_UP: 'attendeeEmailFollowUp', // only exists in UI
  CUSTOM: 'custom',
  REMINDER: 'reminder',
  ADDL_RESOURCE: 'additionalResources',
  CANCELLATION: 'cancellation',
  CONFIRMATION: 'confirmation',
  FEEDBACK_SURVEY: 'feedbackSurvey',
  RECONFIRMATION: 'reconfirmation',
  REQUEST_FOLLOW_UP: 'requestFollowUp',
  RESCHEDULE: 'reschedule',
  THANK_YOU: 'thankYou',
});

export const MAX_NAME_LEN = 150;
export const MAX_TRIGGER_MINUTES = 999 * DAY_MINUTES;


// Zoom meeting related
export const ZM_CREATE_TYPE = Object.freeze({
  OFFLINE: '0',
  ZOOM_MEETING: '1',
  ZOOM_PHONE: '2',
});


// WF Variables
// Ensure that keys of notificationVariableList matches previewVariableList + WF_PREVIEW_LIST
export const WF_PREVIEW_LIST = Object.freeze({
  'attendee_email': 'antwan.cannon@email.com',
  'attendee_first_name': 'Antwan',
  'attendee_full_name': 'Antwan Cannon',
  'attendee_last_name': 'Cannon',
  'attendee_phone_number': '555-555-5555',
  'manage_event_link': 'https://zm.us/DEF456',
  'organizer_email': 'margaret.talen@email.com',
  'organizer_name': 'Margaret Talen',
  'zoom_meeting_link': 'https://us04web.zoom.us/j/0123456789?pwd=ABC123DEF456',
  'questions_and_answers': 'Customer Reference Number: #1313859193',
});

export const FORMAT = {
  STRING: 'string',
  TEXT: 'text',
  CHOICES_ONE: 'choicesOne',
  CHOICES_MANY: 'choicesMany',
  PHONE_NUMBER: 'phoneNumber',
  SELECT: 'select',
  NAME: 'name',
  EMAIL: 'email',
};

export const KIND = {
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  RADIO: 'radio',
  DROPDOWN: 'dropdown',
  PHONE: 'phone',
  SELECT: 'select',
  NAME: 'name',
  EMAIL: 'email',
};

export const OPERATOR = {
  GREATER_THAN: 'greaterThan',
  GREATER_EQUAL: 'greaterOrEqualThan',
  LESS_THAN: 'lessThan',
  LESS_EQUAL: 'lessOrEqualThan',
  IS: 'is',
  IS_NOT: 'isNot',
  CONTAIN: 'contains',
  NOT_CONTAIN: 'doesNotContain',
  START_WITH: 'startWith',
};

export const DESTINATION = {
  EVENT: 'event',
  TEXT: 'text',
  URL: 'url',
};

export const PROVIDER_TYPE = Object.freeze({
  GOOGLE_CALENDAR: 'google',
  OUTLOOK_CALENDAR: 'office365',
  ZOOM: 'zoom',
  APPLE: 'apple',
  GOOGLE_MEET: 'meetgoogle', // DO NOT MODIFY VALUE
  TEAMS_MEETING: 'meetoffice365', // DO NOT MODIFY VALUE
});

export const MEET_INTEGRATION_TYPE = Object.freeze({
  GOOGLE: 'googleMeet',
  TEAMS: 'teamMeet',
});

export const MAX_EMAIL_LEN = 254;
export const MAX_BOOKER_NAME_LEN = 128;
export const MAX_TEXTFIELD_LEN = 128;
export const MAX_RESCHEDULE_REASON_LEN = 300;
export const MAX_SELECTABLE_SLOTS = 40;

export const MAX_STORED_PHONES = 3;

export const MAX_NOTES_LEN = 250;

export const METRIC_NAME = {
  // user login from web
  WEB_LOGIN: 'SCHEDULER_WEB_LOGIN',
  // web user's token refresh
  WEB_REFRESH_TOKEN: 'SCHEDULER_WEB_REFRESH_TOKEN',
  // user login from client
  CLIENT_LOGIN: 'SCHEDULER_CLIENT_LOGIN',
  // client user's token refresh
  CLIENT_REFRESH_TOKEN: 'SCHEDULER_CLIENT_REFRESH_TOKEN',
  // user clicks the generate one time link button
  GENERATE_ONE_TIME_LINK: 'SCHEDULER_GENERATE_ONE_TIME_LINK',
  // send all api call result, success or fail
  API_STATUS: 'SCHEDULER_API_STATUS',
  // record failed api call
  FAILED_API: 'SCHEDULER_FAILED_API',
  // create booking page - recurring (one to one and one to many)
  CREATE_RECURRING: 'CREATE_RECURRING',
  // create booking page - round robin
  CREATE_ROUND_ROBIN: 'CREATE_ROUND_ROBIN',
  // create booking page - collective
  CREATE_COLLECTIVE: 'CREATE_COLLECTIVE',
  // create adhoc - one-off
  CREATE_ONE_OFF: 'CREATE_ONE_OFF',
  // create adhoc - poll
  CREATE_POLL: 'CREATE_POLL',
  // share recurring (one to one and one to many)
  SHARE_RECURRING: 'SHARE_RECURRING',
  // share round robin
  SHARE_ROUND_ROBIN: 'SHARE_ROUND_ROBIN',
  // share collective
  SHARE_COLLECTIVE: 'SHARE_COLLECTIVE',
  // share one-off
  SHARE_ONE_OFF: 'SHARE_ONE_OFF',
  // share poll
  SHARE_MEETING_POLL: 'SHARE_POLL',
  UI_ERROR: 'UI_ERROR',
};

// Zoom meeting related
export const AX_KEYCODE = Object.freeze({
  ARROWLEFT: 37,
  ARROWUP: 38,
  ARROWRIGHT: 39,
  ARROWDOWN: 40,
  SPACE: 32,
  ENTER: 13,
  TAB: 9,
});

export const ESC_KEY_NAME = 'Escape';

const getZoomWebDomain = () => {
  let result = 'https://zoom.us';
  try {
    if (window.__system_params__) {
      result = window.__system_params__.zoomMeetingWebDomain;
    } else if (document.referrer && (window.self !== window.top)) {
      const referrer = new URL(document.referrer);
      // Ignore if an external website has embedded scheduler iframe
      if (referrer.hostname.endsWith('zoom.us') || referrer.hostname.endsWith('zoomdev.us')) {
        result = referrer.origin;
      }
    }
  } catch (error) {}
  return result;
};

export const ZOOM_WEB_DOMAIN = getZoomWebDomain();
export const FREE_ACCOUNT_PURCHASE_LINK =
`${ZOOM_WEB_DOMAIN}/opc/buy?plan=zsched&period=annual&from=zsched&type=basic2pro`;
export const PAID_ACCOUNT_PURCHASE_LINK =
`${ZOOM_WEB_DOMAIN}/opc/buy/add?plan=zsched&subPlan=newsubs&from_sub=zsched&type=add`;
export const ZOOM_ACCOUNT_PAGE_LINK =
`${ZOOM_WEB_DOMAIN}/account`;

export const CLIENT_NOTIFICATIONS = {
  onAppointmentChanged: 'ZMCalendarUINotifAppointmentChanged',
  onConnectionChanged: 'ZMCalendarUINotifConnectionChanged',
  onThemeModeChange: 'onThemeModeChange',
  onTokenRefresh: 'ZMBookingUIAccessTokenRefreshed',
  onZMailAccountChange: 'ZMCalendarUINotifZMailAccountChange',
  onZMailAccountChangeNew: 'ZMCalendarUINotifyZmailAccountChanged',
  onWorkflowChange: 'ZMCalendarUINotifWorkflowChanged',
  onManagedAppointmentChange: 'ZMCalendarUINotifManagedAppointmentChanged',
  onPendingEventChange: 'ZMCalendarUINotifPendingEventChanged',
  onGroupChange: 'ZMCalendarUINotifSchedulerGroupChanged',
  onFreeTrialInfoChange: 'ZMCalendarUINotifyFreeTrialInfoChanged',
  schedulerGenericZPNS: 'ZMCalendarUINotifZoomSchedulerZPNSNotification',
};

export const LICENSE_INFO = {
  ZOOM_SCHEDULER: 1,
  ZCC_SCHEDULER: 2,
  ZOOM_AND_ZCC_SCHEDULER: 3,
};

// eslint-disable-next-line max-len
export const SUBMIT_FEEDBACK = 'https://zoom.sjc1.qualtrics.com/jfe/form/SV_dmAxDuAFUQju4su';
// eslint-disable-next-line max-len
export const ARTICLES_LINK = 'https://support.zoom.us/hc/en-us/categories/14001212186509-Zoom-Scheduler-Support';
// eslint-disable-next-line max-len
export const FAQ_LINK = 'https://support.zoom.us/hc/en-us/articles/13374549636621-Zoom-Scheduler-FAQ';
// eslint-disable-next-line max-len
export const VIDEO_TUTORIAL_LINK = 'https://www.youtube.com/watch?v=cphy_YBxjjk&ab_channel=Zoom';

export const LEARN_MORE_LINK = 'https://www.zoom.com/en/products/appointment-scheduler/';

export const VIDEO_INTRO_LINK = 'https://www.youtube.com/watch?v=L5CTUWjn6uQ';

export const ICLOUD_APP_SPECIFIC_PASSWORD_LINK = 'https://support.apple.com/102654';

export const OFFICE365_SDK_LINK = 'https://appsforoffice.microsoft.com/lib/1.1/hosted/office.js';

// eslint-disable-next-line max-len
export const OFFICE365_ADDIN_LINK = 'https://' + (window.location?.hostname?.split('.')?.slice(-2)?.join('.') || 'zoom.us') + '/office365';

// schedule color list
export const SCHEDULE_COLOR_IDS = [
  {value: '', label: 'No Color'},
  {value: '#FF4F00', label: 'Red'},
  {value: '#FF758E', label: 'Light pink'},
  {value: '#E55CFF', label: 'Magenta'},
  {value: '#8247F5', label: 'Violet'},
  {value: '#0099FF', label: 'Blue'},
  {value: '#0AE8F0', label: 'Cyan'},
  {value: '#17E885', label: 'Lime green'},
  {value: '#CCF000', label: 'Electric lime'},
  {value: '#F8E436', label: 'Bright yellow'},
  {value: '#FFA600', label: 'Orange'},
];

// Booking page color options
export const BOOK_PAGE_COLOR_OPTIONS = ['#0E72ED', '#00539A', '#268543', '#007C7C', '#9053C2', '#FF5F0F', '#E8173D'];

export const GET_CONN_ERROR = {
  INVALID_AUTH: 'Invalid or expired authorization',
  INVALID_ZOOM_ACCOUNT: 'Invalid zoom account',
  INVALID_PUSH_CAL_ID: 'Invalid push cal id',
  INVALID_PULL_CAL_ID: 'Invalid pull cal id',
};

export const SCHEDULE_TAB_VALUES = {
  ALL: 'all',
  ONE_OFF: 'oneOff',
  POLLS: 'polls',
  PANEL: 'panel',
  ROTATING: 'rotating',
  ONE_TO_ONE: 'oneToOne',
  ONE_TO_MANY: 'oneToMany',
};

export const ACCESS_ROLES = Object.freeze({
  owner: 'owner',
  reader: 'reader',
  writer: 'writer',
});

export const USER_ACCESS_TYPE = Object.freeze({
  ADMIN: 'accountAdmin',
  GROUP_ADMIN: 'groupAdmin',
  NORMAL: 'normal',
});
export const ADMIN_ACCESS_TYPES = [USER_ACCESS_TYPE.ADMIN, USER_ACCESS_TYPE.GROUP_ADMIN];

export const hostCalendarColor = [
  '#F6AAB8',
  '#80CBC4',
  '#039be5',
  '#b39ddb',
  '#4fc3f7',
  '#ffcc80',
  '#b0bec5',
  '#ff7043',
  '#ffab00',
];

// MS O365 Accounts have a max of 4 pullCalendarIds
export const MS_MAX_PULL_CALS = 4;

export const CREATED_VIA = Object.freeze({
  managedAppointment: 'managedAppointment',
});

export const SCOPE_TYPE = Object.freeze({
  GROUP: 'group',
  USER: 'user',
  TEAM: 'team',
  ACCOUNT: 'account', // the whole account(organization) level
});

export const FREE_TRIAL_CONDITION = Object.freeze({
  QUAILIFY: 'qualify', // can apply for a trial
  NON_QUALIFY: 'non_qualify', // can not apply for a trial
  TRIALLING: 'trialling', // already using trial license
});

export const ROUTING_FORM_STATUS = Object.freeze({
  PUBLISHED: 'published',
  DRAFT: 'draft',
});

export const UTM_LABEL_MAPPING = Object.freeze({
  utm_source: 'UTM_Source',
  utm_campaign: 'UTM_Campaign',
  utm_medium: 'UTM_Medium',
  utm_term: 'UTM_Term',
  utm_content: 'UTM_Content',
});

export const MAX_CHECK_CALENDARS = Object.freeze({
  [PROVIDER_TYPE.GOOGLE_CALENDAR]: 10,
  [PROVIDER_TYPE.ZOOM]: 10,
  [PROVIDER_TYPE.OUTLOOK_CALENDAR]: 4,
  [PROVIDER_TYPE.APPLE]: 4,
});

export const REQ_WITH_ID_REGX = [
  '/availability/(?!appointments)[0-9a-zA-Z]+(/appointments|/defaultAvailability)?$',
  '/organization/(?!(members|appointments))[0-9a-zA-Z]+$',
  '/organization/(member|appointments)/[0-9a-zA-Z]+$',
  '/pendingevents/poll/[0-9a-zA-Z]+(/schedule)?$',
  '/routing/logic/[0-9a-zA-Z]+$',
].map((p) => new RegExp(p));

export const EVENT_TABS = {
  UPCOMING: 'upcoming',
  PAST: 'past',
  PENDING: 'pending',
  CANCELLED: 'cancelled',
};

export const DEFAULT_EMAIL_PREVIEW_HEIGHT = 480;
export const DEFAULT_SMS_PREVIEW_HEIGHT = 140;

export const BOOKING_LIMIT_TIME_WINDOW = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
});

export const MAX_BOOKINGS = Object.freeze({
  [BOOKING_LIMIT_TIME_WINDOW.DAY]: MAX_BOOKING_LIMIT_PER_DAY,
  [BOOKING_LIMIT_TIME_WINDOW.WEEK]: MAX_BOOKING_LIMIT_PER_DAY * 7,
  [BOOKING_LIMIT_TIME_WINDOW.MONTH]: MAX_BOOKING_LIMIT_PER_DAY * 31,
});

// https://design.zoomdev.us/en/components/avatar#avatar-with-initial-colors-heading
export const PRISM_AVATAR_BGCOLORS =
['#268543', '#007C7C', '#2974A8', '#9053C2', '#555B62', '#B36200', '#9D3B0F', '#E8173D'];
